<template>
  <div id="app">
    <v-card xl-12>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        class="xs-12"
      />
    </v-card>

    <v-spacer />
    <v-btn class="mr-4" color="success" elevation="4" @click="onReset">
      초기화
    </v-btn>
    <v-btn class="mr-4" color="success" elevation="4" @click="onSubmit">
      조 회
    </v-btn>
    <v-btn class="mr-4" color="success" elevation="4" @click="makeExcelFile5">
      makeExcelFile
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import Xlsx from "xlsx"; // mapGetters를 추가한다

const userStore = "userStore";

export default {
  name: "TestPage",
  data() {
    return {
      expanded: [],
      singleExpand: false,
      snack: false,
      snackColor: "",
      snackText: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: "",
      calories: "",
      desserts: [],
      mSUB_NO: "",
      mADMIN_NAME: "",
      mCOMPANY_NAME: "",
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "CASID",
          align: "start",
          sortable: false,
          value: "CPID",
          width: "100px",
        },
        { text: "승인번호", value: "SUB_NO", width: "100px" },
        {
          text: "이름",
          value: "ADMIN_NAME",
          width: "100px",
          filter: (value) => {
            if (!this.calories) return true;

            return value < parseInt(this.calories);
          },
        },
        { text: "상호명", value: "COMPANY_NAME", width: "100px" },
        { text: "전화번호", value: "TEL_NUMBER", width: "100px" },
        { text: "우편번호", value: "POST1", width: "100px" },
        { text: "", value: "data-table-expand", width: "100px" },
      ];
    },
  },
  methods: {
    ...mapActions(userStore, ["GET_DATA"]),
    async onSubmit() {
      try {
        const getResult = await this.GET_DATA({
          cas_id: "",
          sub_no: this.mSUB_NO,
          admin_name: this.mADMIN_NAME,
          company_name: this.mCOMPANY_NAME,
        });
        if (getResult) {
          console.log("로그인 결과", getResult);
          this.desserts = getResult.data;
        } // 페이지 이동!
      } catch (err) {
        console.error(err);
      }
    },
    onReset() {
      this.mSUB_NO = "x";
      this.onSubmit();
      this.mSUB_NO = "";
      this.mADMIN_NAME = "";
      this.mCOMPANY_NAME = "";
    },
    showAlert(row) {
      // set active row and deselect others
      // this.desserts.map((item, index) => {
      //  item.selected = item === row;

      //  this.$set(this.desserts, index, item);
      // });
      alert("Alert! \n" + row.CASID);
      // or just do something with your current clicked row item data
      console.log(row.sugar);
    },
    getitemiron() {
      return "item.iron";
    },
    getitemname() {
      return "item.name";
    },
    makeExcelFile5() {
      const workBook = Xlsx.utils.book_new();
      const workSheet = Xlsx.utils.json_to_sheet(this.desserts);
      Xlsx.utils.book_append_sheet(workBook, workSheet, "example");
      Xlsx.writeFile(workBook, "example.xlsx");
    },
  },
};
</script>

<style scoped>
.table-scrollable {
  overflow-x: auto;
  white-space: nowrap;
}
table {
  border: 1px solid #444444;
  border-collapse: collapse;
}
th,
td {
  border: 1px solid #444444;
  padding: 6px;
}
th {
  background-color: #f5f7f8;
}
td {
  background-color: #f5f8fa;
}
</style>
