<template>
  <div id="Test">
    <test-page />
  </div>
</template>

<script>
import TestPage from "../components/TestPage";

export default {
  name: "Test",

  components: {
    TestPage,
  },
};
</script>
